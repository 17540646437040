@import 'abstracts/variables', 'abstracts/functions', 'abstracts/mixins';
.header {
  $block-name: &;

  position: relative;
  z-index: z(header);

  @include mq($to: xl) {
    position: sticky;
    top: 0;
    right: 0;
    left: 0;
  }

  .headroom-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: $header-headroom-height !important;

    @include mq($to: xl) {
      display: none !important;
    }
  }

  .headroom {
    position: fixed !important;
    z-index: z(header);
    transition: all 0.2s ease-in-out 0s !important;

    &--unfixed {
      position: relative;
      transform: translate3d(0, -110%, 0) !important;

      .header-navigation {
        &__subnav {
          display: none !important;
        }
      }
    }

    &--unpinned {
      position: fixed;
      transform: translateY(-100%);

      .header-navigation {
        &__subnav {
          display: none !important;
        }
      }
    }

    &--pinned {
      position: fixed;
      transform: translateY(0%);
    }
  }

  &__container {
    position: relative;
    transition: all $transition-time;
    transition-delay: 0.6s;

    @include mq($to: xl) {
      z-index: z('other');

      // box-shadow: 0 7px 85px -14px rgb(35 34 94 / 15%);
      background: $white-200;
      transition: all $transition-time;
      transition-delay: 0s;
    }

    &:hover {
      // transition: box-shadow $transition-time;
      box-shadow: 0 7px 85px -14px rgb(35 34 94 / 15%);
      background: $white-200;
      transition-delay: 0s;

      #{$block-name} {
        &__logo {
          .logo_svg__letter {
            transition: none;
            transition-delay: 0s;
            fill: $purple-500;
          }
        }
      }

      .header-navigation {
        &__link {
          transition-delay: 0s;
          color: $text-color;

          &:hover {
            color: $purple-300;
          }

          &:active {
            color: $black-200;
          }

          &--active {
            color: $purple-300;
          }
        }

        &__item {
          &--submenu-opened {
            .header-navigation {
              &__link {
                // transition-delay: 0s;
              }
            }
          }
        }
      }
    }

    &--headroom {
      background: $white-200;
      box-shadow: 0 7px 85px -14px rgb(35 34 94 / 15%);

      #{$block-name} {
        &__inner {
          height: $header-headroom-height;
        }

        &__logo-img {
          width: 77px;
        }
      }

      /* stylelint-disable-next-line no-descending-specificity */
      .header-navigation {
        /* stylelint-disable-next-line no-descending-specificity */
        &__link {
          height: $header-headroom-height;
          padding: 20px;
        }
      }
    }

    &--variant-white {
      @include mq($to: xl) {
        background-color: transparent;
        box-shadow: none;
      }

      #{$block-name} {
        &__logo {
          .logo_svg__letter {
            transition: fill $transition-time;
            transition-delay: 0.6s;
            fill: $white-100;

            @include mq($to: xl) {
              transition-delay: 0s;
            }
          }
        }
      }
    }
  }

  &__right {
    display: flex;
    align-items: center;
    margin-left: 30px;
  }

  &__inner {
    display: flex;
    font-size: 0;
    line-height: 0;
    justify-content: space-between;
    height: $header-desktop-height;

    @include mq($to: xl) {
      height: $header-mob-height;
    }
  }

  &__button {
    .button {
      padding-left: 28px;
      padding-right: 28px;

      @include mq($to: xl) {
        height: 45px;
        padding: 4px 30px;
        border-radius: 10px;
        font-size: 14px;
        line-height: 1.2;
      }
    }

    @include mq($to: 370px) {
      display: none;
    }
  }

  &__button-toggler {
    @include reset-btn;

    display: none;
    position: relative;
    z-index: 0;
    width: 64px;
    height: 64px;
    transform: rotateY(0);
    transition: transform 0.35s;
    margin-right: -10px;
    margin-left: 2px;
    transform-style: preserve-3d;
    will-change: transform;

    @include mq($to: xl) {
      display: block;
    }

    &--open {
      transform: rotateY(180deg);
    }
  }

  &__button-icon-front,
  &__button-icon-back {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -22px;
    margin-left: -22px;
    width: 44px;
    height: 44px;
  }

  &__button-icon-front {
    position: absolute;
    z-index: 1;
    backface-visibility: hidden;
  }

  &__button-icon-back {
    transform: rotateY(180deg);
  }

  &__left {
    display: flex;
    align-items: center;

    @include mq($to: xl) {
      max-width: 75px;
    }
  }

  &__logo {
    display: inline-flex;
    margin: -5px;
    padding: 5px;

    svg {
      width: 100%;
    }

    .logo_svg__letter {
      fill: $purple-500;

      @include mq($to: xl) {
        transition: all $transition-time !important;
      }
    }
  }

  &__submenu-container {
  }

  &__mobile-navigation-wrapper {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    max-height: calc(100vh - $header-mob-height);
    max-height: calc(100dvh - $header-mob-height);
    padding: 20px 50px;
    transform: translateY(calc(-100% - $header-mob-height - 5px));
    transition: transform $transition-time--slow;
    margin-top: $header-mob-height;
    background-color: $white-200;
    overflow-y: auto;
    box-shadow: 0 7px 85px -14px rgb(35 34 94 / 15%);
    will-change: transform;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
      display: none;
    }

    @include mq($to: xl) {
      display: block;
    }

    @include mq($to: md) {
      padding: 20px;
      padding-top: 6px;
    }
  }

  &--submenu-is-opened {
    #{$block-name} {
      &__container {
        transition: box-shadow $transition-time;
        box-shadow: 0 7px 85px -14px rgb(35 34 94 / 15%);
        background: $white-200;
        transition-delay: 0s;

        @include mq($to: xl) {
          box-shadow: none;
          background-color: transparent;
        }
      }
    }

    &#{$block-name} {
      &--variant-white {
        #{$block-name} {
          &__logo {
            .logo_svg__letter {
              fill: $purple-500;

              @include mq($to: xl) {
                fill: $white-100;
              }
            }
          }
        }

        /* stylelint-disable-next-line order/order */
        .header-navigation {
          &__link {
            color: $text-color;

            /* stylelint-disable-next-line order/order */
            &:hover {
              color: $purple-300;
            }

            &:active {
              color: $black-200;
            }

            &--active {
              color: $purple-300;
            }
          }
        }
      }
    }
  }

  &--mm-is-opened {
    #{$block-name} {
      &__mobile-navigation-wrapper {
        transform: translateY(-1px);
        opacity: 1;
      }

      &__logo {
        .logo_svg__letter {
          @include mq($to: xl) {
            fill: $purple-500 !important;
          }
        }
      }

      &__container {
        @include mq($to: xl) {
          box-shadow: 0 7px 85px -14px rgb(35 34 94 / 15%) !important;
          background: $white-200 !important;
          transition: all $transition-time !important;
          transition-delay: 0s !important;
        }
      }
    }
  }

  &--is-pinned + .layout__main {
    .industry-layout__aside-inner {
      top: calc($header-headroom-height + 20px);
    }

    .sticky-navigation {
      top: calc($header-headroom-height - 1px);

      @include mq($to: xl) {
        top: calc($header-mob-height - 1px);
      }
    }
  }

  &--variant-white {
    /* stylelint-disable-next-line no-descending-specificity */
    .header-navigation {
      /* stylelint-disable-next-line no-descending-specificity */
      &__link {
        transition-delay: 0.6s;

        @include mq($to: xl) {
          transition-delay: 0s;
        }
      }
    }

    svg {
      // fill: $purple-300;
    }

    .toggler_svg__dots {
      // fill: $purple-100;
    }

    .toggler_svg__round {
      // fill: $purple-300;
    }

    .toggler_svg__dot-light {
      // fill: #e7e0ff;
    }

    #{$block-name} {
      &__container {
        &:hover {
          @include mq($to: xl) {
            box-shadow: none;
            background-color: transparent;
            transition: all $transition-time;
          }

          #{$block-name} {
            &__logo {
              .logo_svg__letter {
                @include mq($to: xl) {
                  fill: $white-100;
                }
              }
            }
          }
        }
      }
    }
  }

  &--is-scrolled {
    #{$block-name} {
      &__container {
        @include mq($to: xl) {
          box-shadow: 0 7px 85px -14px rgb(35 34 94 / 15%) !important;
          background: $white-200 !important;
        }
      }
    }

    .logo_svg__letter {
      @include mq($to: xl) {
        fill: $purple-500 !important;
      }
    }
  }
}
