@import 'abstracts/variables', 'abstracts/functions', 'abstracts/mixins';
.header-navigation {
  $block-name: &;

  margin-right: 40px;

  @include mq($to: xl) {
    display: none;
  }

  //   #{$block-name} {
  //   }

  &__item {
    font-size: 0;
    line-height: 0;

    &--submenu-opened {
      #{$block-name} {
        &__subnav {
          transform: translate3d(0, -2px, 0);
          opacity: 1;
          visibility: visible;
        }

        &__link {
          color: $purple-300;

          &::before {
            width: 100%;
          }
        }

        &__icon-submenu {
          transform: scale(1, -1);
        }
      }
    }

    &--submenu-active {
      #{$block-name} {
        &__subnav {
          // z-index: -1;
        }
      }
    }
  }

  &__link {
    display: inline-flex;
    align-items: center;
    position: relative;
    height: $header-desktop-height;
    padding: 20px;
    text-decoration: none;
    color: $black-200;
    font-family: $font-family--headings;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 110%;
    transition: color $transition-time;

    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 50%;
      width: 0;
      height: 2px;
      transform: translateX(-50%);
      background-color: $purple-300;
      transition: width $transition-time--slow;
    }

    /* stylelint-disable-next-line order/order */
    &:hover {
      color: $purple-300;
    }

    &:active {
      color: $black-200;
    }

    &--active {
      color: $purple-300;
    }
  }

  &__list {
    @include unlist;

    display: flex;
    font-size: 0;
    line-height: 0;
  }

  &__open-menu-button {
  }

  &__subnav {
    position: absolute;
    z-index: -2;
    top: 100%;
    right: 0;
    left: 0;
    overflow: hidden;
    transform: translate3d(0, -105%, 0);
    transition: all $transition-time--slow;
    opacity: 0;
    visibility: hidden;
    padding-top: 50px;
    padding-bottom: 50px;
    background: $white-200;
    box-shadow: 0 7px 85px -14px rgb(35 34 94 / 15%);
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    will-change: transform, opacity;
  }

  &__subnav-list {
    @include unlist;

    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin: -11px;
    gap: 20px;
  }

  &__subnav-item {
    &--not-industries {
      &:not(:nth-last-child(-n + 2)) {
        padding-bottom: 20px;
        border-bottom: 1px solid $white-400;
      }
    }
  }

  &__subnav-info-wrap {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__subnav-arrow-icon {
    position: absolute;
    z-index: 5;
    top: 50%;
    right: 11px;
    visibility: hidden;
    opacity: 0;
    transform: translateY(-50%);
    transition: all $transition-time--slow;
  }

  &__subnav-link {
    display: inline-flex;
    position: relative;
    gap: 14px;
    text-decoration: none;
    color: $black-200;
    font-family: $font-family;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    width: 100%;
    padding: 11px;
    transition: all $transition-time--slow;

    &:hover {
      border-radius: 9px;
      background: $white-300;

      & #{$block-name} {
        &__subnav-icon--has-foodtech {
          background: $white-200;
          color: $green-500;
        }

        &__subnav-icon--has-foodtech {
          background: $white-200;
          color: $green-500;
        }

        &__subnav-arrow-icon {
          visibility: visible;
          opacity: 1;
        }
      }
    }

    &:active {
      color: $black-200;
    }

    &--icon-without-box {
      align-items: flex-start;
    }

    span {
      color: $purple-400;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
    }

    &--active {
      color: $purple-300;
    }

    &--with-description {
      align-items: flex-start;
    }

    &--disabled {
      color: $dark-gray-200;
      pointer-events: none;

      &:hover {
        color: $dark-gray-200;
      }

      #{$block-name} {
        &__subnav-icon {
          color: $dark-gray-200;
        }
      }
    }
  }

  &__icon-submenu {
    @include reset-text-indents;

    flex: 0 0 auto;
    margin-left: 5px;
    margin-right: -5px;
    transition: transform $transition-time--slow;

    svg {
      stroke-width: 1.5;
    }
  }

  &__foodtech {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
  }

  &__foodtech-image {
    @include size(96px);

    display: flex;
    flex: 0 0 auto;
    border-radius: 10px;
    background: $grad-purple-vertical-inv;
    align-items: center;
    justify-content: center;
    margin-right: 20px;

    img {
      image-rendering: -webkit-optimize-contrast; /* stylelint-disable-line */
    }
  }

  &__foodtech-left {
    display: flex;
    flex: 0 0 auto;
    flex-wrap: nowrap;
    align-items: center;
    margin-right: 65px;
  }

  &__foodtech-link {
    margin-top: -7px;
  }

  &__foodtech-right {
    flex: 1 1 auto;
  }

  &__foodtech-text {
    color: $dark-gray-400;
    font-family: $font-family;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }

  &__foodtech-text-box {
    margin-top: 8px;
  }

  &__foodtech-title {
    color: $black-300;
    font-family: $font-family--headings;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%;
    letter-spacing: -0.56px;
  }

  &__subnav-menu-wrapper {
    display: grid;
    grid-template-columns: 1fr;
  }

  &__subnav-menu {
    padding-right: 40px;
    border-right: 1px solid $white-400;
  }

  &__subnav-icon {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    color: $purple-400;
    transition: all $transition-time--slow;

    &--has-foodtech {
      width: 56px;
      height: 56px;
      border-radius: 10px;
      background: $white-300;
    }
  }

  &__subnav-description {
    color: $dark-gray-400;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }

  &__subnav-slot {
    display: flex;
    padding-left: 40px;
  }

  &__subnav--has-slot {
    #{$block-name} {
      &__subnav-menu-wrapper {
        grid-template-columns: 2fr 1fr;
      }

      &__subnav-list {
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }

  &--variant-white {
    #{$block-name} {
      &__link {
        color: $white-100;

        /* stylelint-disable-next-line order/order */
        &:hover {
          color: $purple-300;
        }

        &:active {
          color: $black-200;
        }

        &--active {
          color: $green-200;
        }
      }

      &__item {
        &--submenu-opened {
          #{$block-name} {
            &__link {
              // color: $purple-300;
            }
          }
        }
      }
    }
  }
}
