@import 'abstracts/variables', 'abstracts/functions', 'abstracts/mixins';
.foodtech-slot {
  $block-name: &;

  &__foodtech {
    @include mq($to: xl) {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
    }
    @include mq($to: md) {
      display: block;
    }
  }

  &__foodtech-image {
    @include size(96px);

    display: flex;
    flex: 0 0 auto;
    border-radius: 10px;
    background: $grad-purple-vertical-inv;
    align-items: center;
    justify-content: center;
    margin-right: 20px;

    @include mq($to: xl) {
      @include size(78px);
    }

    img {
      image-rendering: -webkit-optimize-contrast; /* stylelint-disable-line */
    }
  }

  &__foodtech-left {
    display: flex;
    flex: 0 0 auto;
    flex-wrap: nowrap;
    align-items: center;

    @include mq($to: xl) {
      margin-right: 65px;
    }

    @include mq($to: md) {
      margin-right: 0;
    }
  }

  &__foodtech-link {
    margin-top: -7px;
  }

  &__foodtech-right {
    flex: 1 1 auto;
    margin-top: 20px;

    @include mq($to: xl) {
      margin-top: 0;
    }

    @include mq($to: md) {
      margin-top: 15px;
    }
  }

  &__foodtech-text {
    color: $dark-gray-400;
    font-family: $font-family;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }

  &__foodtech-text-box {
    margin-top: 8px;
  }

  &__foodtech-title {
    color: $black-300;
    font-family: $font-family--headings;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%;
    letter-spacing: -0.56px;
  }
}
