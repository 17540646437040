@import 'abstracts/variables', 'abstracts/functions', 'abstracts/mixins';
.navigation-mobile {
  $block-name: &;

  //   #{$block-name} {
  //   }

  @include reset-text-indents;

  &__list {
    @include unlist;
  }

  &__nav-item {
    position: relative;

    &:not(:last-child) {
      border-bottom: 1px solid $white-400;
    }

    &--submenu-opened {
      #{$block-name} {
        &__open-menu-button {
          background-color: $purple-100;

          svg {
            transform: scale(1, -1);
          }
        }
      }
    }
  }

  &__link {
    color: $black-200;
    font-family: $font-family--headings;
    font-size: $font-size-s;
    font-style: normal;
    font-weight: $font-weight-l;
    line-height: $line-height-2xs;
    text-decoration: none;
    display: inline-flex;
    width: 100%;
    max-width: 100%;
    margin: 0;
    padding: 34px 0;

    &:active {
      // color: $color-main;
    }

    &--active {
      color: $color-main;
    }
  }

  &__open-menu-button-wrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: absolute;
    top: 0;
    right: 0;
    width: 63.8px;
    height: 88.6px;
  }

  &__open-menu-button {
    @include reset-btn;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 34px;
    height: 34px;
    border-radius: $border-radius-rounded;
    background-color: $white-400;
    color: #585a61;
    transition: background-color $transition-time;

    svg {
      transform: scale(1, 1);
      transition: transform 0.2s ease 0s;
      stroke-width: 1.5;
    }
  }

  &__subnav-wrap {
  }

  &__subnav {
    @include unlist;

    display: flex;
    flex-wrap: wrap;
    width: 66.6666%;
    gap: 0 30px;
    margin-top: -11px;
    padding-bottom: 23px;

    @include mq($to: lg) {
      width: 100%;
      margin-left: -11px;
      margin-right: -11px;
      gap: 0 15px;
    }
  }

  &__nav-subitem {
    width: calc(50% - 15px);

    @include mq($to: lg) {
      width: calc(50% - 8px);
    }

    @include mq($to: sm) {
      width: 100%;
    }
  }

  &__nav--is-coming-soon {
    color: $purple-400;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }

  &__nav-description {
    color: $dark-gray-400;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }

  &__nav-icon {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    color: $purple-400;

    &--has-foodtech {
      width: 56px;
      height: 56px;
      border-radius: 10px;
      background: $white-300;
    }

    &--disabled {
      color: $dark-gray-200;
    }
  }

  &__nav-link {
    display: inline-flex;
    gap: 14px;
    color: $black-200;
    font-family: $font-family;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    text-decoration: none;
    padding: 11px;

    &--active {
      color: $color-main;
    }

    @include mq($to: md) {
      font-size: 18px;
      line-height: 150%;
      width: 100%;
    }

    &--disabled {
      color: $dark-gray-200;

      &:hover {
        color: $dark-gray-200;
      }

      #{$block-name} {
        &__subnav-icon {
          color: $dark-gray-200;
        }
      }
    }
  }

  &__button {
    display: none;

    @include mq($to: sm) {
      display: block;
    }
  }

  &__foodtech-slot {
    padding-bottom: 34px;
  }
}
